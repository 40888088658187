import React, { useEffect } from "react";
import { useFilters } from "../../templates/filter-context/useFilters";
import { Button, Divider, Form } from "antd";
import { Col, Row } from "antd";
import { SupportedControls } from "../../molecules";
import { FormProvider, useForm } from "react-hook-form";
import { SingleOrderContextProvider } from "../../organisms";
import { useNavigate, useLocation } from "react-router-dom";
import {
  createQuery,
  removeUndefinedFields,
  queryStringToObject,
  removeUnusedFields,
} from "./Filter.utils";
import { createShema, joiResolver } from "../single-order/SingleOrder.utils";
export const Filters: React.FC<{
  filtersContols: any;
  resetPagination: any;
}> = ({ filtersContols, resetPagination }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const schema = filtersContols && createShema(filtersContols);
  const methods = useForm({
    resolver: schema && joiResolver(schema),
  });

  const { setFilters, filters } = useFilters();

  useEffect(() => {
    if (location.search) {
      const filterObjectFromURL = removeUndefinedFields(
        queryStringToObject(location.search)
      );
      navigate({
        pathname: location.pathname,
        search: createQuery(filterObjectFromURL),
      });
      setFilters(removeUnusedFields(filterObjectFromURL));
      methods.reset(filterObjectFromURL);
      return;
    } else {
      setFilters({});
    }
  }, []);

  const submit = () => {
    methods.handleSubmit((data) => {
      navigate({
        pathname: location.pathname,
        search: createQuery(removeUndefinedFields(data)),
      });
      setFilters(removeUndefinedFields(removeUnusedFields(data)));
      resetPagination();
    }, console.error)();
  };

  const reset = () => {
  const values= methods.getValues() ?? {}
   Object.keys(values).map(key=>{
    methods.resetField(key);
   })
    if (Object.keys(filters).length > 0) {
      methods.reset();
      setFilters({});
      resetPagination();
      navigate({
        pathname: location.pathname,
        search: "",
      });
    }
  };

  return (
    <SingleOrderContextProvider disableReadMode={true}>
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              submit();
            }
          }}
        >
          <Row gutter={16}>
            {filtersContols
              .sort((a: any, b: any) => {
                return a.position - b.position;
              })
              .map((control: any) => {
                return (
                  <Col span={control.displaying_size} key={control.uuid}>
                    <SupportedControls control={control} />
                  </Col>
                );
              })}
          </Row>
          {filtersContols?.length > 0 && (
            <>
              <Row gutter={16}>
                <Col span={24}>
                  <Button
                    style={{
                      marginRight: "10px",
                    }}
                    type="primary"
                    onClick={() => submit()}
                  >
                    Zastosuj filtry
                  </Button>
                  <Button type="default" onClick={reset}>
                    Resetuj filtry
                  </Button>
                </Col>{" "}
              </Row>
              <Divider
                style={{
                  marginTop: "12px",
                  marginBottom: "12px",
                }}
              />
            </>
          )}
        </Form>
      </FormProvider>
    </SingleOrderContextProvider>
  );
};
