import { Button, Row, notification } from "antd";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { SingleOrderActionsWrapper } from "./SingleOrderActions.styled";
import { useMode } from "../../organisms";
import { updateOrder } from "../../../service/client";
export const SingleOrderActions: React.FC<{
  id: any;
}> = ({ id }) => {
  const { mode, setMode } = useMode();
  const queryClient = useQueryClient();
  const methods = useFormContext();

  const updateOrderMutation = useMutation(updateOrder, {
    onSuccess: async () => {
      try {
        await queryClient.refetchQueries("/orders");
        notification.success({
          message: "Zapisano zmiany",
          style: {
            background: "#52c41a30",
          },
        });
        setMode("read");
      } catch (error) {
        notification.error({
          message: "Błąd Pobierania nowych danych",
        });
        console.error("Wystąpił błąd podczas odświeżania danych:", error);
      }
    },
    onError: () => {
      notification.error({
        message: "Błąd zapisu danych",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const { mutateAsync, isLoading } = updateOrderMutation;

  const submit = () => {
    methods.handleSubmit((data) => {
      mutateAsync({ id, body: data });
    }, console.error)();
  };

  if (mode === "edit") {
    return (
      <Row
        justify="end"
        style={{
          height: "32px",
        }}
      >
        <SingleOrderActionsWrapper>
          <Button
            type="primary"
            disabled={isLoading}
            danger
            onClick={() => setMode("read")}
          >
            Anuluj
          </Button>
          <Button type="primary" onClick={submit} loading={isLoading}>
            Zapisz
          </Button>
        </SingleOrderActionsWrapper>
      </Row>
    );
  }

  return null;
};
