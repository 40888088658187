import styled from "styled-components";
import { Space as AntSpace, Typography as AntTypography } from "antd";

export const Space = styled(AntSpace)`
    margin-bottom: 20px;
`;

export const Title = styled(AntTypography.Title)`
    margin-top: 0;
    margin-bottom: 0!important;
    color: white!important;

    .anticon-copy{
        color: white;
    }
`;
