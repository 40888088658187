/* eslint-disable react/react-in-jsx-scope */
import { Avatar, Flex, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { DataType } from "./TaskList.types";

export const columns: ColumnsType<DataType> = [
  {
    title: "Zadanie",
    dataIndex: "name",
    key: "name",
    width: "40%",
  },
  {
    title: "Przypisany",
    dataIndex: "assigne",
    key: "assigne",
    render: (_, { assigne }) => (
      <Flex align="center">
        <Avatar.Group>
          {assigne.map((user) => {
            return (
              <Tooltip title={user.name} key={user.name} placement="top">
                <Avatar
                  style={{
                    backgroundColor: user.icon_color,
                    cursor: "pointer",
                  }}
                  size="small"
                >
                  {user.short_name}
                </Avatar>
              </Tooltip>
            );
          })}
        </Avatar.Group>
      </Flex>
    ),
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (_, { status }) => (
      <Tag color={status.color} key={status.color}>
        {status.text}
      </Tag>
    ),
  },
  {
    title: "Priorytet",
    key: "priority",
    dataIndex: "priority",
    render: (_, { priority }) => (
      <Tag color={priority.color} key={priority.color}>
        {priority.text}
      </Tag>
    ),
  },
  {
    title: "Dodano",
    key: "created",
    dataIndex: "created",
    render: (_, { created }) => (
      <p style={{ textAlign: "center" }}>{created.slice(0, 10)}</p>
    ),
  },
  {
    title: "Nowe kom.",
    key: "unread_comments",
    dataIndex: "unread_comments",
    render: (_, { unread_comments }) => (
      <p
        style={{
          color: parseInt(unread_comments as string) > 0 ? "red" : "green",
          textAlign: "center",
        }}
      >
        {unread_comments}
      </p>
    ),
  },
];
