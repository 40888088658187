import Joi from 'joi'

export const createShema = (order: any) => {
    if (!order) return
    let objextToreturn: any = {}

    order.forEach((element: any) => {
        if (element.field_type !== 'comments') {
            const pre_name = element.pre_name !== "" && element.pre_name ? `${element.pre_name}` : "";
            if (pre_name === '') {
                if (element.field_type === 'input-number') {
                    objextToreturn[element.uuid] = element.required ? Joi.number().required().messages({
                        'number.base': 'Pole musi być liczbą',
                        'any.required': 'Pole musi być liczbą'
                    }) : Joi.any()
                } else {
                    objextToreturn[element.uuid] = element.required ? Joi.any().custom((value, helpers) => {
                        if (Array.isArray(value) && value.length === 0) {
                            return helpers.error('any.required');
                        }
                        if (value === '') {
                            return helpers.error('any.required');
                        }
                        return value;
                    }).required().messages({ 'any.required': 'Pole jest wymagane' }) : Joi.any()
                }
            } else {
                objextToreturn[pre_name] = objextToreturn[pre_name] || {}
                if (element.field_type === 'input-number') {
                    objextToreturn[pre_name][element.uuid] = element.required ? Joi.number().required().messages({
                        'number.base': 'Pole musi być liczbą',
                        'any.required': 'Pole musi być liczbą'
                    }) : Joi.any()
                } else {
                    objextToreturn[pre_name][element.uuid] = element.required ? Joi.any().empty('').required().messages({ 'any.required': 'Pole jest wymagane' }) : Joi.any()
                }
            }
        } else {
            objextToreturn[element.uuid] = Joi.any()
        }
    });

    return Joi.object(objextToreturn)
}

export const joiResolver = (schema: any) => {
    return (data: any) => {
        const { error, value } = schema.validate(data, { abortEarly: false });

        return {
            values: error ? {} : value,
            errors: error
                ? error.details.reduce((previous: any, currentError: any) => {
                    return {
                        ...previous,
                        [currentError.path.join('.')]: {
                            type: currentError.type,
                            message: currentError.message,
                        },
                    };
                }, {})
                : {}
        };
    };
};

function isNumericUsingNumberIsNaN(str: any) {
    return !Number.isNaN(Number(str));
}

export const createFormInitialValue = (order: any) => {
    if (!order) return
    let objextToreturn: any = {}
    order.forEach((element: any) => {
        const pre_name = element.pre_name && element.pre_name !== "" ? `${element.pre_name}.` : "";
        if (element.field_type !== "attachments" && element.field_type !== "comments") {
            if (element.value !== '') {
                if (isNumericUsingNumberIsNaN(element.value) && typeof element.value !== 'boolean') {
                    objextToreturn[`${pre_name}${element.uuid}`] = parseInt(element.value) ?? undefined
                } else {
                    objextToreturn[`${pre_name}${element.uuid}`] = element.value ?? undefined
                }
            } else {
                objextToreturn[`${pre_name}${element.uuid}`] = undefined;
            }

        }
    });
    return objextToreturn
}

export const createFormInitialTaskValue = (preName: any, order: any) => {
    if (!order) return
    let objextToreturn: any = {}
    order.forEach((element: any) => {
        objextToreturn[element.uuid] = element.value ?? undefined
    });
    if (preName !== '') {
        return {
            [preName]: objextToreturn
        }
    } else {
        return objextToreturn
    }

}

export const createTitle = (title_fields: any, order: any) => {
    let title = "";
    title_fields?.forEach((element: string) => {
        const singleOrder = order.find((e: any) => e.uuid === element);
        if (['input-number', 'input-text', 'textarea', 'data-picker', 'select'].includes(singleOrder?.field_type)) {
            if (singleOrder) {
                if (title === "") {
                    title = singleOrder.value
                } else {
                    if (singleOrder.value) title = title + " - " + singleOrder.value
                }
            }
        }

    });

    return title;
}