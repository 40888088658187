import { Form, Input, Typography } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useMode } from "../../organisms";
import { FormIteam } from "../GlobalControl.styled";

export const Search: React.FC<{ control: any }> = ({ control }) => {
  const pre_name =
    control.pre_name && control.pre_name !== "" ? `${control.pre_name}.` : "";
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${pre_name}${control.uuid}` });
  const { mode } = useMode();

  if (mode === "edit") {
    return (
      <Form.Item
        label={control.field_name}
        required={control.required}
        validateStatus={invalid ? "error" : undefined}
        help={invalid ? error?.message : null}
      >
        <Input disabled={!control.writeable} {...field} allowClear />
      </Form.Item>
    );
  }

  return (
    <FormIteam label={control.field_name}>
      <Typography.Text strong copyable>
        {control.value}
      </Typography.Text>
    </FormIteam>
  );
};
