import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { Content } from "./TasksPage.styled";
import {
  TasksList,
  TaskDetails,
  SingleOrderContextProvider,
} from "../../organisms";
import { useFilters } from "../../templates/filter-context/useFilters";
import { useQueryClient } from "react-query";
export const TasksPage: React.FC = () => {
  const { setFilters } = useFilters();
  const queryClient = useQueryClient();

  const user: any = queryClient.getQueryData(["/current-user"]);

  useEffect(() => {
    setFilters({
      status: ["NEW", "ASSIGNED", "IN_PROGRESS"],
      assignes: [`${user?.user_id ?? ""}`],
    });
  }, []);

  return (
    <SingleOrderContextProvider disableReadMode={false}>
      <Content>
        <Row
          gutter={16}
          style={{
            height: "100%",
          }}
        >
          <Col span={12}>
            <TasksList />
          </Col>
          <Col span={12}>
            <TaskDetails />
          </Col>
        </Row>
      </Content>
    </SingleOrderContextProvider>
  );
};
