import { ReactNode, useState } from "react";
import { SingleOrderContext } from "./SingleOrderContext";
import { Mode } from "./SingleOrderContext.types";
import React from "react";
export const SingleOrderContextProvider: React.FC<{
  children: ReactNode;
  disableReadMode: boolean;
  global_actions?: boolean;
}> = ({ children, disableReadMode, global_actions = false }) => {
  const defaultMode = disableReadMode ? "edit" : "read";

  const [mode, setMode] = useState<Mode>(defaultMode);

  return (
    <SingleOrderContext.Provider value={{ mode, setMode, global_actions }}>
      {children}
    </SingleOrderContext.Provider>
  );
};
