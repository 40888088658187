import { Empty, Form, notification, Spin } from "antd";
import React, { useState } from "react";
import { useCommentTranslation } from "./useCommentTranslation";
import { CommentSectionWrapper } from "./CimmentsModule.styled";
import { CommentSection } from "react-comments-section";
import "react-comments-section/dist/index.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addComment,
  getComments,
  updateComment,
  deleteComment,
} from "../../../service/client";
import { createComments } from "./CommentsModule.utils";

export const CommentsModule: React.FC<{ control: any }> = ({ control }) => {
  const { transation } = useCommentTranslation();
  const queryClient = useQueryClient();

  const [data, setData] = useState<any>([]);

  const user: any = queryClient.getQueryData(["/current-user"]);

  const { refetch, isFetching } = useQuery<any, Error>(
    ["/comments", control.value],
    () => getComments(control.value),
    {
      enabled: !!control.value,
      onSuccess: (data) => {
        const comments: any = createComments(data);
        setData(comments);
        queryClient.invalidateQueries("/tasks-stats");
        queryClient.refetchQueries("/tasks-stats");
      },
    }
  );

  const addCommentMutation = useMutation(addComment, {
    onSuccess: async () => {
      try {
        await refetch();
        notification.success({
          message: "Dodano komentarz",
          style: {
            background: "#52c41a30",
          },
        });
      } catch (error) {
        await refetch();
        notification.error({
          message: "Błąd podczas dodawnia komentarza",
          style: {
            background: "#ff4d4f30",
          },
        });
        console.error("Błąd podczas dodawnia komentarza:", error);
      }
    },
    onError: () => {
      refetch();
      notification.error({
        message: "Błąd podczas dodawnia komentarza",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });
  const { mutateAsync } = addCommentMutation;

  const updateCommentMutation = useMutation(updateComment, {
    onSuccess: async () => {
      try {
        await refetch();
        notification.success({
          message: "Zedytowano komentarz",
          style: {
            background: "#52c41a30",
          },
        });
      } catch (error) {
        await refetch();
        notification.error({
          message: "Błąd podczas edycji komentarza",
          style: {
            background: "#ff4d4f30",
          },
        });
        console.error("Błąd podczas edycji komentarza:", error);
      }
    },
    onError: () => {
      refetch();
      notification.error({
        message: "Błąd podczas edycji komentarza",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });
  const { mutateAsync: updateMutateAsync } = updateCommentMutation;

  const removeCommentMutation = useMutation(deleteComment, {
    onSuccess: async () => {
      try {
        await refetch();
        notification.success({
          message: "Usunięto komentarz",
          style: {
            background: "#52c41a30",
          },
        });
      } catch (error) {
        await refetch();
        notification.error({
          message: "Błąd podczas usuwania komentarza",
          style: {
            background: "#ff4d4f30",
          },
        });
        console.error("Błąd podczas usuwania komentarza:", error);
      }
    },
    onError: () => {
      refetch();
      notification.error({
        message: "Błąd podczas usuwania komentarza",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });
  const { mutateAsync: removeMutateAsync } = removeCommentMutation;

  const addCommentAction = (data: any) => {
    mutateAsync({ task: control.value, comment: data.text });
  };

  const updateCommentAction = (data: any) => {
    updateMutateAsync({ id: data.comId, comment: data.text });
  };

  const removeCommentAction = (data: any) => {
    removeMutateAsync(data.comIdToDelete);
  };

  const customNoComment = () => {
    if (isFetching) {
      return <Spin />;
    }
    return <Empty />;
  };
  if (user) {
    return (
      <div onClick={() => transation()}>
        <Form.Item
          label={control.field_name}
          // tooltip={control.description}
        >
          <CommentSectionWrapper>
            <CommentSection
              currentUser={{
                currentUserId: user?.user_id.toString(),
                currentUserImg: `https://ui-avatars.com/api/name=${
                  user?.short_name
                }&background=${user?.icon_color?.replace(
                  /#/g,
                  ""
                )}&color=ffffff`,
                currentUserProfile: "",
                currentUserFullName: user?.name,
              }}
              logIn={{
                loginLink: "",
                signupLink: "",
              }}
              submitBtnStyle={{
                background: "#1677ff",
                boxShadow: "0 2px 0 rgba(5, 145, 255, 0.1)",
                fontSize: "14px",
                height: "32px",
                borderRadius: "6px",
                border: "1px solid transparent",
                transition: "all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)",
                fontWeight: "400",
              }}
              cancelBtnStyle={{
                background: "#ff4d4f",
                boxShadow: "0 2px 0 rgba(5, 145, 255, 0.1)",
                fontSize: "14px",
                height: "32px",
                borderRadius: "6px",
                border: "1px solid transparent",
                transition: "all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)",
                fontWeight: "400",
                color: "white",
              }}
              imgStyle={{
                width: "25px",
                height: "25px",
                pointerEvents: "none",
              }}
              hrStyle={{ display: "none" }}
              titleStyle={{ display: "none" }}
              overlayStyle={{ padding: 0 }}
              commentData={data}
              removeEmoji={true}
              formStyle={{
                display: !control.writeable ? "none" : null,
                background: "white",
                paddingLeft: 0,
                paddingRight: 0,
              }}
              onEditAction={(data: { text: string }) =>
                updateCommentAction(data)
              }
              onSubmitAction={(data: { text: string }) =>
                addCommentAction(data)
              }
              onDeleteAction={(data: { text: string }) =>
                removeCommentAction(data)
              }
              customNoComment={() => customNoComment()}
            />
          </CommentSectionWrapper>
        </Form.Item>
      </div>
    );
  }
  return null;
};
