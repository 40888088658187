import React from "react";
import { Button, Flex, notification, Popconfirm, Row, Tag } from "antd";
import { Space, Title } from "./SingleOrderHeader.styled";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useMode } from "../../organisms";
import { deleteOrder } from "../../../service/client";
import { useMutation, useQueryClient } from "react-query";

export const SingleOrderHeader: React.FC<{
  title: string;
  labels: any;
  id: any;
  delete_permission: any;
}> = ({ title, labels, id, delete_permission }) => {
  const { mode, setMode } = useMode();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(deleteOrder, {
    onSuccess: async () => {
      try {
        await queryClient.refetchQueries("/orders");
        notification.success({
          message: "Usunięto pomyślnie",
          style: {
            background: "#52c41a30",
          },
        });
        setMode("read");
      } catch (error) {
        notification.error({
          message: "Błąd Pobierania nowych danych",
          style: {
            background: "#ff4d4f30",
          },
        });
        console.error("Wystąpił błąd podczas odświeżania danych:", error);
      }
    },
    onError: () => {
      notification.error({
        message: "Błąd uswania",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  return (
    <Row justify="space-between">
      <Space size={"small"} direction="vertical">
        {!!title && (
          <Tag color="#17a2b8">
            <Title level={4} copyable>
              {title}
            </Title>
          </Tag>
        )}
        <Space size={"small"} direction="horizontal">
          {labels?.map((e: any) => {
            return (
              <Tag color={e?.color} key={e?.text}>
                {e?.text}
              </Tag>
            );
          })}
        </Space>
      </Space>
      <Flex gap={8}>
        <Popconfirm
          title="Usuń pozycje"
          description="Czy na pewno chcesz usunąć wybraną pozycję?"
          onConfirm={() => mutateAsync(id)}
          okText="Tak"
          cancelText="Nie"
        >
          <Button
            loading={isLoading}
            disabled={mode === "edit" || !delete_permission}
            shape="circle"
            danger
            icon={<DeleteOutlined />}
            size={"large"}
          />
        </Popconfirm>
        <Button
          disabled={isLoading}
          onClick={() => setMode(mode === "read" ? "edit" : "read")}
          type={mode === "read" ? "dashed" : "link"}
          shape="circle"
          icon={<EditOutlined />}
          size={"large"}
        />
      </Flex>
    </Row>
  );
};
