import { LogoutOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Flex,
  Modal,
  Space,
  Typography,
  notification,
} from "antd";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

export const UserInfo: React.FC<{ refetch: any; collapsed: any }> = ({
  refetch,
  collapsed,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const user: any = queryClient.getQueryData(["/current-user"]);
  const [modal1Open, setModal1Open] = useState(false);

  const [logoutIsLoading, setLogoutIsLoading] = useState(false);
  const logout = async () => {
    setLogoutIsLoading(true);
    try {
      Cookies.remove("accessToken");
      await refetch();
      navigate({
        pathname: location.pathname,
        search: "",
      });
      notification.success({
        message: "Wylogowano",
        style: {
          background: "#52c41a30",
        },
      });
    } catch (error) {
      notification.error({
        message: "Błąd podczas wylogowania",
        style: {
          background: "#ff4d4f30",
        },
      });
      console.error("Wystąpił błąd podczas wylogowywania", error);
      setLogoutIsLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          paddingLeft: "8px",
          paddingRight: "8px",
          paddingBottom: "8px",
          width: "100%",
        }}
      >
        <Flex gap={8} justify="space-between">
          <Space>
            <Badge
              size="small"
              //count={1}
              style={{
                cursor: "pointer",
              }}
            >
              <Avatar
                style={{
                  background: user.icon_color,
                  cursor: "pointer",
                }}
                size="small"
                // onClick={() => setModal1Open(!modal1Open)}
              >
                {user.short_name}
              </Avatar>
            </Badge>

            {!collapsed && (
              <Typography.Text
                strong
                style={{ color: "#ffffff", whiteSpace: "nowrap" }}
              >
                {user.name}
              </Typography.Text>
            )}
          </Space>
          <Button
            type="link"
            danger
            loading={logoutIsLoading}
            onClick={() => logout()}
            icon={<LogoutOutlined />}
          />
        </Flex>
      </div>
      <Modal
        maskClosable={false}
        title="Powiadomienia"
        style={{ top: 20 }}
        open={modal1Open}
        okText="Odczytaj wszystkie"
        cancelText="Zamknij"
        onOk={() => setModal1Open(false)}
        onCancel={() => setModal1Open(false)}
      >
        {new Array(10).fill("x").map((e) => {
          return (
            <Card
              key={e}
              title="Pushes open the window"
              size="small"
              style={{
                marginBottom: "20px",
              }}
              extra={<a href="#">Więcej</a>}
            >
              <Space
                style={{
                  marginBottom: "20px",
                }}
              >
                Text Powiadomienia Text Powiadomienia Text Powiadomienia Text
                Powiadomienia Text Powiadomienia Text Powiadomienia
              </Space>
              <Flex justify="start">
                <Button size="small" type="primary">
                  Odczytaj
                </Button>
              </Flex>
            </Card>
          );
        })}
      </Modal>
    </>
  );
};
