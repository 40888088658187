import { Modal, message, Spin, Result } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { findModel } from "../../templates/main-template/MainTamplate.utils";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { ModalForm } from "../../molecules";
import { SingleOrderContextProvider } from "../single-order-context/SingleOrderContextProvider";
import { addOrder, getAddModalControls } from "../../../service";
import { MainTemplateLoading } from "../../templates/main-template/MainTemplate.styled";
import { createShema, joiResolver } from "../single-order/SingleOrder.utils";

export const AddOrderModal: React.FC<{
  open: any;
  setOpen: any;
  isLoading: any;
  mutateAsync: any;
  hardcodedControls: any;
}> = ({ open, setOpen, mutateAsync, isLoading, hardcodedControls }) => {
  const { name } = useParams();
  const queryClient = useQueryClient();
  const models: any = queryClient.getQueryData(["/models"]);
  const { model, error: modelError } = useMemo(() => findModel(models, name), [
    models,
    name,
  ]);

  const { data, error, isError, isFetching } = useQuery<any, Error>(
    ["/modal-controls", model.id],
    () => getAddModalControls(model.id),
    {
      enabled: !!model && open,
    }
  );

  const schema = data && createShema([...hardcodedControls, ...data.model]);
  const methods = useForm({
    resolver: schema && joiResolver(schema),
  });

  const submit = () => {
    methods.handleSubmit(async (data) => {
      const id = model.id;
      await mutateAsync({ id, body: data });
      methods.reset();
      setOpen(false);
    }, console.error)();
  };

  const canel = () => {
    methods.reset();
    setOpen(false);
  };

  return (
    <Modal
      maskClosable={false}
      confirmLoading={isLoading}
      title="Dodaj nowy rekord"
      centered
      open={open}
      okButtonProps={{
        disabled: isError || isFetching,
        loading: isFetching,
      }}
      onOk={submit}
      onCancel={canel}
      width={1000}
    >
      {isFetching && (
        <MainTemplateLoading>
          <Spin size="large" />
        </MainTemplateLoading>
      )}

      {data && !modelError && (
        <SingleOrderContextProvider disableReadMode={true}>
          <FormProvider {...methods}>
            <ModalForm
              controls={data.model}
              hardcodedControls={hardcodedControls}
            />
          </FormProvider>
        </SingleOrderContextProvider>
      )}

      {isError ||
        (modelError && (
          <Result
            status="403"
            title="BŁĄD"
            subTitle="Przepraszamy, wystąpił błąd. Skontaktuj się z administratorem"
          />
        ))}
    </Modal>
  );
};
