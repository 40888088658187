import styled from "styled-components";

export const CommentSectionWrapper = styled.div`

.infoStyle {
    font-size: 12px !important;
}
.fullName {
    font-size: 14px !important;
}
.replyBtn {
    display: none !important;
}
a {
    pointer-events: none;
    cursor: default;
}
`;