import { createContext } from "react";

interface FilterContextProps {
  filters: any;
  setFilters: any;
}

export const FilterContext = createContext<FilterContextProps | undefined>(
  undefined
);
