import styled from 'styled-components';
import { Table as AntTable } from 'antd';

export const Table = styled(AntTable)`
  .ant-table-tbody > tr:hover {
    cursor: pointer;
  }

  .selected-row {
    background:#00000050 !important;
  }

  .after-deadline{
    background:#ff000050;
  }
`;