export const createComments = (data: any) => {
    if (Array.isArray(data)) {
        return data.map((comment) => {
            return {
                userId: comment.author.user_id.toString(),
                comId: comment.id,
                fullName: comment.author.name,
                text: comment.comment,
                avatarUrl: `https://ui-avatars.com/api/name=${comment.author.short_name
                    }&background=${comment.author.icon_color.replace(
                        /#/g,
                        ""
                    )}&color=ffffff`,
                replies: [],
            };
        });
    }
    return [];
};