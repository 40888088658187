import { useEffect } from "react";

export const useCommentTranslation = () => {
    const transation = () => {
        setTimeout(() => {
            const inputElements = document.querySelectorAll(".postComment") as any;
            if (inputElements)
                inputElements.forEach(function (input: any) {
                    input.placeholder = "Dodaj nowy komentarz";
                });
            const buttonPostElements = document.querySelectorAll(".postBtn") as any;
            if (buttonPostElements)
                buttonPostElements.forEach(function (input: any) {
                    input.textContent = "Dodaj";
                });
            const buttonCancelElements = document.querySelectorAll(
                ".cancelBtn"
            ) as any;
            if (buttonCancelElements)
                buttonCancelElements.forEach(function (input: any) {
                    input.textContent = "Anuluj";
                });
        }, 0);
    };

    useEffect(() => {
        transation();
    }, []);

    return { transation }
}

