import { useEffect, useState } from "react";

export const useTaskPageContetnSize = (mode?: any) => {
    const [tableHeight, setTableHeight] = useState<any>(window.innerHeight);
    const [detailsHeight, setDetailsHeight] = useState<any>(window.innerHeight);

    function getDivHeightById(divId: string) {
        // Get the div element by its ID
        var divElement = document.getElementById(divId);

        // Check if the element exists
        if (divElement) {
            // Get the height of the element
            var divHeight = divElement.offsetHeight;
            return divHeight + 135;
        } else {
            console.error("No element found with ID:", divId);
            return null;
        }
    }

    useEffect(() => {
        function updateTableHeight() {
            const headerHeight = getDivHeightById('task-details-header') ?? 300
            const availableTableHeight = window.innerHeight - 238;
            const availableDetailHeight = mode && mode === 'edit' ? window.innerHeight - headerHeight : window.innerHeight - headerHeight;
            setTableHeight(availableTableHeight);
            setDetailsHeight(availableDetailHeight);
        }

        updateTableHeight();
        window.addEventListener("resize", updateTableHeight);
        return () => {
            window.removeEventListener("resize", updateTableHeight);
        };
    }, [mode]);

    return { tableHeight, detailsHeight }
}

