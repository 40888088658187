import { ReactNode, useState } from "react";
import { FilterContext } from "./FilterContext";
import React from "react";
export const FilterContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [filters, setFilters] = useState<any>();

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};
