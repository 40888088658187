import { Form, InputNumber, Typography } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useMode } from "../../organisms";
import { FormIteam } from "../GlobalControl.styled";

export const NumberInput: React.FC<{ control: any }> = ({ control }) => {
  const pre_name =
    control.pre_name && control.pre_name !== "" ? `${control.pre_name}.` : "";
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${pre_name}${control.uuid}` });
  const { mode } = useMode();
  const formatter = (value: any) => value.toString().replace(/\./g, ",");
  const parser = (value: any) => value.replace(/,/g, ".");
  if (mode === "edit") {
    return (
      <Form.Item
        tooltip={
          !control.writeable && mode === "edit"
            ? "Brak uprawnień do edycji"
            : control.description
        }
        label={control.field_name}
        required={control.required}
        validateStatus={invalid ? "error" : undefined}
        help={invalid ? error?.message : null}
      >
        <InputNumber
          style={{
            width: "100%",
          }}
          disabled={!control.writeable}
          {...field}
          formatter={formatter}
          parser={parser}
        />
      </Form.Item>
    );
  }

  return (
    <FormIteam
      // tooltip={control.description}
      label={control.field_name}
    >
      <Typography.Text
        strong
        copyable={
          control.value ? control.value?.toString()?.replace(/\./g, ",") : ""
        }
      >
        {control.value ? control.value.toString()?.replace(/\./g, ",") : ""}
      </Typography.Text>
    </FormIteam>
  );
};
