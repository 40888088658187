import { Col, Form, Row } from "antd";
import React from "react";

import { SupportedControls } from "../../molecules";

export const ModalForm: React.FC<{
  controls: any;
  hardcodedControls: any;
}> = ({ controls, hardcodedControls }) => {
  return (
    <Form layout="vertical">
      <Row gutter={16}>
        {hardcodedControls
          .sort((a: any, b: any) => {
            return a.position - b.position;
          })
          .map((control: any) => {
            return (
              <Col span={control.displaying_size} key={control.uuid}>
                <SupportedControls control={control} />
              </Col>
            );
          })}
        {controls
          .sort((a: any, b: any) => {
            return a.position - b.position;
          })
          .map((control: any) => {
            return (
              <Col span={control.displaying_size} key={control.uuid}>
                <SupportedControls control={control} />
              </Col>
            );
          })}
      </Row>
    </Form>
  );
};
