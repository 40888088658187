import { Form, Slider, Typography } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useMode } from "../../organisms";

export const Range: React.FC<{ control: any }> = ({ control }) => {
  const pre_name =
    control.pre_name && control.pre_name !== "" ? `${control.pre_name}.` : "";
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${pre_name}${control.uuid}` });
  const { mode } = useMode();

  if (mode === "edit") {
    return (
      <Form.Item
        label={control.field_name}
        // tooltip={
        //   !control.writeable && mode === "edit"
        //     ? "Brak uprawnień do edycji"
        //     : control.description
        // }
        required={control.required}
        validateStatus={invalid ? "error" : undefined}
        help={invalid ? error?.message : null}
      >
        <Slider
          disabled={!control.writeable}
          range
          {...field}
          defaultValue={[0, 0]}
          max={control.options[1].value}
          min={0}
        />
      </Form.Item>
    );
  }

  return (
    <Form.Item
      label={control.field_name}
      //  tooltip={control.description}
    >
      <Typography.Text strong copyable>
        {control.value}
      </Typography.Text>
    </Form.Item>
  );
};
