import React, { createContext } from "react";
import { Mode } from "./SingleOrderContext.types";
interface SingleOrderContextProps {
  mode: Mode;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  global_actions: boolean;
}

export const SingleOrderContext = createContext<
  SingleOrderContextProps | undefined
>(undefined);
