import { Flex, Layout, Space, Tag } from "antd";

import React, { useState } from "react";
import { useQuery } from "react-query";
import { getTasksStats } from "../../../service";

const { Header: AntHeader } = Layout;

export const Header: React.FC = () => {
  const [labels, setLabels] = useState([]);
  const { error, isFetching } = useQuery<any, Error>(
    ["/tasks-stats"],
    () => getTasksStats(),
    {
      onSuccess(data) {
        setLabels(data);
      },
      refetchInterval: 10 * 60 * 1000,
    }
  );
  return (
    <AntHeader
      style={{
        height: labels?.length > 0 ? "50px" : "0px",
        paddingLeft: "4px",
        paddingRight: "4px",
        lineHeight: 0,
        background: "#fff",
      }}
    >
      <Flex
        align="mid"
        style={{
          height: "100%",
        }}
      >
        {!isFetching && !error && (
          <Space wrap>
            {labels.map((label: any) => {
              return (
                <Tag
                  style={{
                    padding: "5px",
                    fontSize: "14px",
                  }}
                  key={label?.color + label?.text}
                  color={label?.color ?? "#000000"}
                >
                  {label?.text}
                </Tag>
              );
            })}
          </Space>
        )}
      </Flex>
    </AntHeader>
  );
};
