import { Divider, Flex, Skeleton } from "antd";
import React from "react";
import { SingleOrderContextProvider } from "../../organisms";
import { SupportedControls } from "..";

export const GlobalActions: React.FC<{ total: any; global_actions: any }> = ({
  global_actions,
}) => {
  return global_actions ? (
    <Flex justify="end" gap={8} align="center">
      <SingleOrderContextProvider disableReadMode={false} global_actions={true}>
        {global_actions?.map((control: any) => {
          if (
            control.field_type === "redirect" ||
            control.field_type === "form-modal"
          ) {
            return <SupportedControls control={control} key={control.uuid} />;
          }
        })}
      </SingleOrderContextProvider>
    </Flex>
  ) : (
    <Flex justify="end" gap={16}>
      <Skeleton.Input active size="default" />
      <Skeleton.Input active size="default" />
    </Flex>
  );
};
