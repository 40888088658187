import { Layout } from "antd";

import React from "react";

const { Footer: AntFooter } = Layout;

export const Footer: React.FC = () => {
  return (
    <AntFooter
      style={{
        textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      Created by FeedWeb
    </AntFooter>
  );
};
