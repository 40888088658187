import { Content as AntContent } from "antd/es/layout/layout";
import styled from "styled-components";

export const Content = styled(AntContent)`

    padding: 8px;
    margin-top: 16px;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    background: #fff;
`;