import styled from "styled-components";

export const SingleOrderWrapper = styled.div`

    margin-top: 20px;
    margin-bottom: 20px;
`;

export const SingleOrderFormWrapper = styled.div<{ $background_color?: string }>`
    background-color:  ${props => `${props.$background_color ?? ' #f8f8f8'}`};
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #d1d5db;
`;
