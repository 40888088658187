import { Form } from "antd";
import styled from "styled-components";

export const FormIteam = styled(Form.Item)`
  &.ant-form-item {
    .ant-form-item-control {
      border: 1px solid #d9d9d9;
      background: white;
      padding-left: 11px;
      padding-right: 11px;
      border-radius: 6px;
      
    }
  }
`;