import { useQueryClient } from "react-query";
import React, { useEffect, useMemo } from "react";
import { Button, Result, Spin } from "antd";
import { OrdersPage, TasksPage } from "../../pages/";
import { MainTemplateLoading } from "./MainTemplate.styled";
import { useNavigate, useParams } from "react-router-dom";
import { findModel } from "./MainTamplate.utils";
import { replacePolishChars } from "../../../view/slide-menu/SlideMenu.utils";

const VIEW_TYPE: { [key: string]: React.ComponentType<any> } = {
  forms_list: OrdersPage,
  tasks: TasksPage,
};

export const MainTemplate: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { name } = useParams();
  const models: any = queryClient.getQueryData(["/models"]);

  const { model, error } = useMemo(() => findModel(models, name), [
    models,
    name,
  ]);

  useEffect(() => {
    if (!name && !model) {
      navigate(
        `${replacePolishChars(models[0].name.toLowerCase().replace(/ /g, "-"))}`
      );
      return;
    }
  }, [name, model]);

  if (model) {
    const Component = VIEW_TYPE[model.subpage_type];

    if (!Component) {
      return (
        <Result
          status="404"
          title="BŁĄD"
          subTitle="Przepraszamy, wystąpił błąd. Skontaktuj się z administratorem"
          extra={<Button type="primary">Back Home</Button>}
        />
      );
    }

    return <Component />;
  }

  if (error) {
    return (
      <Result
        status="403"
        title="BŁĄD"
        subTitle="Przepraszamy, wystąpił błąd. Skontaktuj się z administratorem"
        extra={<Button type="primary">Back Home</Button>}
      />
    );
  }

  return (
    <MainTemplateLoading>
      <Spin size="large" />
    </MainTemplateLoading>
  );
};
