export const queryStringToObject = (queryString: string) => {
    const searchParams = new URLSearchParams(queryString);
    let result: any = {};

    for (let [key, value] of searchParams.entries() as any) {
        const keyValue = decodeURIComponent(value);

        if (keyValue.includes(",")) {
            result[key] = keyValue.split(",");
        } else {
            result[key] = keyValue;
        }

    }
    return result;
}

export const removeUndefinedFields = (obj: any) => {
    for (let key in obj) {
        if ((obj[key] === false || obj[key] === undefined || obj[key] === null || obj[key] === 'null' || obj[key] === '' || obj[key]?.length === 0) && key !== 'id' && key !== 'page') {
            delete obj[key];
        }
        if (Array.isArray(obj[key])) {
            if (obj[key]?.length === 2 && obj[key][0] === 0 && obj[key][1] === 0) {
                delete obj[key];
            }
        }
    }
    return obj;
};

export const removeUnusedFields = (obj: any) => {
    for (let key in obj) {
        if (key === 'page' || key === 'id') {
            delete obj[key];
        }
    }
    return obj;
};

export const createQuery = (filter: any) => {
    return new URLSearchParams(filter).toString();
}

export const setPageParam = (search: string, page: number) => {
    const params = new URLSearchParams(search);
    params.delete('page')
    params.set('page', page.toString())
    return params.toString()
}


