import React, { useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  createFormInitialValue,
  createTitle,
  createShema,
  joiResolver,
} from "./SingleOrder.utils";
import { SingleOrderForm } from "../single-order-form/SingleOrderForm";
import { SingleOrderHeader } from "../../molecules/";
import { SingleOrderContextProvider } from "../../organisms";
import {
  SingleOrderWrapper,
  SingleOrderFormWrapper,
} from "./SingleOrder.styled";

export const SingleOrder: React.FC<{
  item: any;
  id: any;
  model: any;
  labels: any;
  title_fields: any;
  delete_permission: any;
  background_color?: any;
}> = ({
  item,
  id,
  labels,
  title_fields,
  delete_permission,
  background_color,
}) => {
  const schema = createShema(item);
  const methods = useForm({
    resolver: joiResolver(schema),
  });

  const title = useMemo(() => createTitle(title_fields, item), [
    title_fields,
    item,
  ]);

  useEffect(() => {
    methods.reset(createFormInitialValue(item));
    return;
  }, []);

  return (
    <SingleOrderWrapper>
      <SingleOrderContextProvider disableReadMode={false}>
        <FormProvider {...methods}>
          <SingleOrderFormWrapper $background_color={background_color}>
            <SingleOrderHeader
              title={title}
              labels={labels}
              id={id}
              delete_permission={delete_permission}
            />
            <SingleOrderForm controls={item} id={id} />
          </SingleOrderFormWrapper>
        </FormProvider>
      </SingleOrderContextProvider>
    </SingleOrderWrapper>
  );
};
