import { Button, Form, Typography, notification } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMode } from "../../organisms";
import axios from "axios";
import Cookies from "js-cookie";

export const Redirect: React.FC<{ control: any; pre_name?: any }> = ({
  control,
}) => {
  const { mode, global_actions } = useMode();

  const navigate = useNavigate();
  const location = useLocation();
  const url = control?.value.with_search_parameters
    ? control.value.url + location.search
    : control.value.url;
  const [loading, setLoading] = useState(false);
  const click = () => {
    setLoading(true);
    const accessToken = Cookies.get("accessToken");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        setLoading(false);
      })
      .catch(() => {
        notification.error({
          message: "Pobierania nowych danych",
          style: {
            background: "#ff4d4f30",
          },
        });
        setLoading(false);
      });
  };

  if (control.value.redirect_type === "internal") {
    return (
      <Form.Item
        // tooltip={
        //   !control.writeable && mode === "edit"
        //     ? "Brak uprawnień do edycji"
        //     : control.description
        // }
        style={{ margin: 0, marginTop: global_actions ? "" : "30px" }}
      >
        <Button
          type={control.type ?? "dashed"}
          onClick={() => {
            if(control?.value?.open_in_new_tab){
              window.open(control?.value?.url, '_blank');
            }else{
              const url_data = control.value.url.split("?");
              const pathname = url_data[0];
              let search = control?.value.with_search_parameters
                ? location.search
                : "";
              if (url_data.length > 1) {
                if (search.length == 0) {
                  search = "?" + url_data[1];
                } else {
                  search = search + "&" + url_data[1];
                }
              }
              navigate({
                pathname: pathname,
                search: search,
              });
            }

          }}
          disabled={mode === "edit" || !control.writeable}
        >
          {control?.field_name}
        </Button>
      </Form.Item>
    );
  }

  if (control.value.redirect_type === "external") {
    return (
      <Form.Item
        // tooltip={control?.description}
        style={{ margin: 0, marginTop: global_actions ? "" : "30px" }}
      >
        <Button
          type={control.type ?? "dashed"}
          onClick={click}
          target={control?.value?.open_in_new_tab ? "_blank" : "_self"}
          disabled={mode === "edit"}
          loading={loading}
        >
          {control?.field_name}
        </Button>
      </Form.Item>
    );
  }

  return (
    <Form.Item
      label={control?.field_name}
      // tooltip={control?.description}
      style={{ margin: 0 }}
    >
      <Typography.Text strong copyable>
        {control?.field_name}
      </Typography.Text>
    </Form.Item>
  );
};
