import React from "react";
import {
  TextInput,
  NumberInput,
  TextArea,
  DataPicker,
  DataPickerRange,
  Attachments,
  Select,
  Checkbox,
  PriceInput,
  Search,
  Range,
  Steps,
  CommentsModule,
  Redirect,
  FormModal,
} from "../../atoms/";

const COMPONENT: { [key: string]: React.ComponentType<any> } = {
  textarea: TextArea,
  checkbox: Checkbox,
  select: Select,
  "multi-select": Select,
  "input-text": TextInput,
  "input-number": NumberInput,
  "input-price": PriceInput,
  "data-picker": DataPicker,
  "data-picker-range": DataPickerRange,
  attachments: Attachments,
  search: Search,
  range: Range,
  steps: Steps,
  comments: CommentsModule,
  redirect: Redirect,
  "form-modal": FormModal,
};

type SupportedControlsPropsType = { control: any };

export const SupportedControls: React.FC<SupportedControlsPropsType> = (
  props
) => {
  if (!props?.control?.field_type) {
    return null;
  }

  const Component = COMPONENT[props.control.field_type];

  if (!Component) {
    return <>{`Invalid field_type: ${props.control.field_type}`}</>;
  }

  return <Component {...props} />;
};
