import { Flex, notification, Pagination, Skeleton, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addOrder, getFilters } from "../../../service";
import { Content } from "../../pages";
import { findModel } from "../../templates/main-template/MainTamplate.utils";
import { AddOrderModal } from "../add-order-modal/AddOrderModal";
import { setPageParam } from "../filters/Filter.utils";
import { Filters } from "../filters/Filters";
import { GlobalActions } from "../../molecules";

export const OrdersWraper: React.FC<{
  children: any;
  limit: any;
  setLimit: any;
  setOffSet: any;
  total: any;
  isFetching: any;
  global_actions: any;
}> = ({ children, limit, setLimit, setOffSet, total, global_actions }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { name } = useParams();
  const queryClient = useQueryClient();
  const models: any = queryClient.getQueryData(["/models"]);

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  const { model } = useMemo(() => findModel(models, name), [models, name]);

  const { data: filtersData, isFetching: isFetchingFilters } = useQuery<
    any,
    Error
  >(["/filters", model?.id], () => getFilters(model.id), {
    enabled: !!model,
  });

  const { mutateAsync, isLoading } = useMutation(addOrder, {
    onSuccess: async () => {
      try {
        await queryClient.refetchQueries("/orders");
        notification.success({
          message: "Zapisano zmiany",
          style: {
            background: "#52c41a30",
          },
        });
      } catch (error) {
        notification.success({
          message: "Zapisano zmiany",
          style: {
            background: "#52c41a30",
          },
        });
        notification.error({
          message: "Pobierania nowych danych",
          style: {
            background: "#ff4d4f30",
          },
        });
        console.error("Wystąpił błąd podczas odświeżania danych:", error);
      }
    },

    onError: () => {
      notification.error({
        message: "Błąd zapisu danych",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const resetPagination = () => {
    setOffSet(0);
    setPage(1);
    const params = new URLSearchParams(location.search);
    params.delete("page");
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("page")) {
      setPage(parseInt(params.get("page") as string));
      setOffSet((parseInt(params.get("page") as string) - 1) * limit);
    }
  }, []);

  return (
    <Content>
      {filtersData && !isFetchingFilters && (
        <Filters
          filtersContols={filtersData}
          resetPagination={resetPagination}
        />
      )}
      {isFetchingFilters && <Skeleton active />}

      <Flex justify="space-between">
        {total && (
          <>
            <Space size={"small"}>
              <Pagination
                current={page}
                total={total}
                pageSize={limit}
                showSizeChanger={true}
                onShowSizeChange={(_, size) => setLimit(size)}
                onChange={(page) => {
                  setOffSet((page - 1) * limit);
                  setPage(page);
                  navigate({
                    pathname: location.pathname,
                    search: setPageParam(location.search, page),
                  });
                }}
              />
            </Space>
            <Space size={"small"}>
              <GlobalActions total={total} global_actions={global_actions} />
            </Space>
          </>
        )}
      </Flex>
      {children}
      {total && (
        <Pagination
          total={total}
          current={page}
          pageSize={limit}
          showSizeChanger={true}
          onShowSizeChange={(_, size) => setLimit(size)}
          onChange={(page) => {
            setOffSet((page - 1) * limit);
            setPage(page);
            navigate({
              pathname: location.pathname,
              search: setPageParam(location.search, page),
            });
          }}
        />
      )}
      <AddOrderModal
        open={open}
        setOpen={setOpen}
        mutateAsync={mutateAsync}
        isLoading={isLoading}
        hardcodedControls={[]}
      />
    </Content>
  );
};
