import { SingleOrder } from "../../organisms";
import React, { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { findModel } from "../../templates/main-template/MainTamplate.utils";
import { useFilters } from "../../templates/filter-context/useFilters";
import { getOrders } from "../../../service/client";
import { MainTemplateLoading } from "../../templates/main-template/MainTemplate.styled";
import { Button, Empty, Flex, Result, Space, Spin } from "antd";
import { createQuery } from "../../organisms/filters/Filter.utils";
import { OrdersWraper } from "../../organisms/";
import { GlobalActions } from "../../molecules";

export const OrdersPage: React.FC = () => {
  const { filters } = useFilters();
  const queryClient = useQueryClient();
  const { name } = useParams();
  const models: any = queryClient.getQueryData(["/models"]);
  const [limit, setLimit] = useState(20);
  const [offset, setOffSet] = useState(0);
  const { model, error: modelError } = useMemo(() => findModel(models, name), [
    models,
    name,
  ]);

  const query = createQuery({ ...filters, limit: limit, offset: offset });

  const [global_actions, setGlobal_actions] = useState([]);
  const [total, setTotal] = useState(null);
  const { data, error, isFetching } = useQuery<any, Error>(
    ["/orders", model?.id, query],
    () => getOrders(model.id, query),
    {
      enabled: !!model && !!filters,
      onSuccess(data) {
        setTotal(data.data.count);
        Array.isArray(data.data.global_actions) &&
          setGlobal_actions(data.data.global_actions);
      },
    }
  );

  const items = data && data?.data.results;
  const title_fields = data && data?.title_fields;
  const delete_permission = data && data?.data.delete_permission;

  if (error || modelError) {
    return (
      <Result
        status="403"
        title="BŁĄD"
        subTitle="Przepraszamy, wystąpił błąd. Skontaktuj się z administratorem"
        extra={<Button type="primary">Back Home</Button>}
      />
    );
  }

  return (
    <OrdersWraper
      limit={limit}
      setLimit={setLimit}
      setOffSet={setOffSet}
      total={total}
      isFetching={isFetching}
      global_actions={global_actions}
    >
      {Array.isArray(items) && items?.length > 0
        ? items.map((e: any) => {
            return (
              <SingleOrder
                key={e.id}
                id={e.id}
                item={e.item}
                labels={e.labels}
                background_color={e.background_color}
                delete_permission={delete_permission}
                model={model}
                title_fields={title_fields}
              />
            );
          })
        : data && (
            <>
              <Flex justify="flex-end">
                <Space size={"small"}>
                  <GlobalActions total={1} global_actions={global_actions} />
                </Space>
              </Flex>
              <Empty />
            </>
          )}

      {isFetching && (
        <MainTemplateLoading>
          <Spin size="large" />
        </MainTemplateLoading>
      )}
    </OrdersWraper>
  );
};
