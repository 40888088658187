import { Button, Col, Form, Row, Space } from "antd";
import React from "react";

import { SupportedControls, SingleOrderActions } from "../../molecules";

export const SingleOrderForm: React.FC<{
  controls: any;
  id: any;
}> = ({ controls, id }) => {
  return (
    <Form layout="vertical">
      <Row gutter={16}>
        {controls
          .sort((a: any, b: any) => {
            return a.position - b.position;
          })
          .map((control: any) => {
            return (
              <Col span={control.displaying_size} key={control.uuid}>
                <SupportedControls control={control} />
              </Col>
            );
          })}
      </Row>
      <SingleOrderActions id={id} />
    </Form>
  );
};
