import { Form, Select as AntSelect, Typography } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useMode } from "../../organisms";
import { FormIteam } from "../GlobalControl.styled";

export const Select: React.FC<{ control: any }> = ({ control }) => {
  const pre_name =
    control.pre_name && control.pre_name !== "" ? `${control.pre_name}.` : "";
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${pre_name}${control.uuid}` });
  const { mode } = useMode();

  // #TODO mode musi przhcodzić z backendu
  if (mode === "edit") {
    if (control.field_type === "multi-select") {
      return (
        <Form.Item
          label={control.field_name}
          // tooltip={
          //   !control.writeable && mode === "edit"
          //     ? "Brak uprawnień do edycji"
          //     : control.description
          // }
          required={control.required}
          validateStatus={invalid ? "error" : undefined}
          help={invalid ? error?.message : null}
        >
          <AntSelect
            filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
            disabled={!control.writeable}
            mode="multiple"
            options={control.options}
            {...field}
          />
        </Form.Item>
      );
    }

    if (control.field_type === "select") {
      return (
        <Form.Item
          label={control.field_name}
          // tooltip={control.description}
          required={control.required}
          validateStatus={invalid ? "error" : undefined}
          help={invalid ? error?.message : null}
        >
          <AntSelect
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            disabled={!control.writeable}
            options={control.options}
            {...field}
          />
        </Form.Item>
      );
    }
  }

  return (
    <FormIteam
      label={control.field_name}
      // tooltip={control.description}
    >
      <Typography.Text strong copyable={control.value}>
        {Array.isArray(control.value)
          ? [
              ...control.value.map((element: any) => {
                const x = control.options.find((e: any) => e.value === element);
                return x.label;
              }),
            ].join(", ")
          : control.options.find((e: any) => e.value === control.value)?.label}
      </Typography.Text>
    </FormIteam>
  );
};
