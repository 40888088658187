import { Form, InputNumber, Typography } from "antd";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { useMode } from "../../organisms";
import { FormIteam } from "../GlobalControl.styled";

export const PriceInput: React.FC<{ control: any }> = ({ control }) => {
  const pre_name =
    control.pre_name && control.pre_name !== "" ? `${control.pre_name}.` : "";
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${pre_name}${control.uuid}` });
  const methods = useFormContext();
  const { mode } = useMode();
  const formatToTwoDecimalPlaces = (num: any) => {
    return parseFloat(num).toFixed(2);
  };

  const handleBlur = (e: any) => {
    const formattedValue = formatToTwoDecimalPlaces(e.target.value);
    methods.setValue(control.uuid, formattedValue);
  };

  if (mode === "edit") {
    return (
      <Form.Item
        // tooltip={
        //   !control.writeable && mode === "edit"
        //     ? "Brak uprawnień do edycji"
        //     : control.description
        // }
        label={control.field_name}
        required={control.required}
        validateStatus={invalid ? "error" : undefined}
        help={invalid ? error?.message : null}
      >
        <InputNumber
          disabled={!control.writeable}
          style={{
            width: "100%",
          }}
          {...field}
          onBlur={handleBlur}
        />
      </Form.Item>
    );
  }

  return (
    <FormIteam
      //  tooltip={control.description}
      label={control.field_name}
    >
      <Typography.Text strong copyable={control.value}>
        {control.value}
      </Typography.Text>
    </FormIteam>
  );
};
