import { Form, DatePicker, Typography } from "antd";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { useMode } from "../../organisms";
import dayjs from "dayjs";
import { FormIteam } from "../GlobalControl.styled";

const DATE_FORMAT = "YYYY-MM-DD";

export const DataPicker: React.FC<{ control: any }> = ({ control }) => {
  const pre_name =
    control.pre_name && control.pre_name !== "" ? `${control.pre_name}.` : "";
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${pre_name}${control.uuid}` });
  const methods = useFormContext();
  const { mode } = useMode();

  if (mode === "edit") {
    return (
      <Form.Item
        label={control.field_name}
        // tooltip={
        //   !control.writeable && mode === "edit"
        //     ? "Brak uprawnień do edycji"
        //     : control.description
        // }
        required={control.required}
        validateStatus={invalid ? "error" : undefined}
        help={invalid ? error?.message : null}
      >
        <DatePicker
          disabled={!control.writeable}
          allowClear={false}
          style={{
            width: "100%",
          }}
          {...field}
          value={field.value && dayjs(field.value, DATE_FORMAT)}
          onChange={(_, value) =>
            methods.setValue(`${pre_name}${control.uuid}`, value)
          }
          format={DATE_FORMAT}
        />
      </Form.Item>
    );
  }

  return (
    <FormIteam
      label={control.field_name}
      // tooltip={control.description}
    >
      <Typography.Text strong copyable={control.value}>
        {control.value}
      </Typography.Text>
    </FormIteam>
  );
};
