import { Button, Row, notification } from "antd";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { SingleOrderActionsWrapper } from "./TaskDetailsActions.styled";
import { useMode } from "../../organisms";
import { updateTask } from "../../../service/client";
import { queryStringToObject } from "../../organisms/filters/Filter.utils";

export const TaskDetailsActions: React.FC = () => {
  const { mode, setMode } = useMode();
  const queryClient = useQueryClient();
  const methods = useFormContext();
  const paramas = queryStringToObject(location.search);

  const updateTaskMutation = useMutation(updateTask, {
    onSuccess: async () => {
      try {
        await queryClient.refetchQueries("/tasks");
        await queryClient.refetchQueries("/task-details", paramas?.id);
        notification.success({
          message: "Zapisano zmiany",
          style: {
            background: "#52c41a30",
          },
        });
        setMode("read");
      } catch (error) {
        notification.error({
          message: "Błąd Pobierania nowych danych",
          style: {
            background: "#ff4d4f30",
          },
        });
        console.error("Wystąpił błąd podczas odświeżania danych:", error);
      }
    },
    onError: () => {
      notification.error({
        message: "Błąd zapisu danych",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const { mutateAsync, isLoading } = updateTaskMutation;

  const submit = () => {
    methods.handleSubmit((data) => {
      mutateAsync({ id: paramas?.id, body: data });
    }, console.error)();
  };

  if (mode === "edit") {
    return (
      <Row
        justify="end"
        style={{
          height: "32px",
        }}
      >
        <SingleOrderActionsWrapper>
          <Button
            type="primary"
            disabled={isLoading}
            danger
            onClick={() => setMode("read")}
          >
            Anuluj
          </Button>
          <Button type="primary" onClick={submit} loading={isLoading}>
            Zapisz
          </Button>
        </SingleOrderActionsWrapper>
      </Row>
    );
  }

  return null;
};
