import { Flex, Pagination } from "antd";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMode } from "../../organisms";
import { useFilters } from "../../templates/filter-context/useFilters";
import {
  createQuery,
  queryStringToObject,
  removeUndefinedFields,
  removeUnusedFields,
  setPageParam,
} from "../filters/Filter.utils";

export const TaskListWraper: React.FC<{
  children: any;
  limit: any;
  setLimit: any;
  total: any;
  setOffSet: any;
  page: any;
  setPage: any;
}> = ({ children, limit, setLimit, setOffSet, page, setPage, total }) => {
  const { mode } = useMode();
  const navigate = useNavigate();
  const location = useLocation();
  const { setFilters } = useFilters();

  useEffect(() => {
    if (location.search) {
      const filterObjectFromURL = removeUndefinedFields(
        queryStringToObject(location.search)
      );
      navigate({
        pathname: location.pathname,
        search: createQuery(filterObjectFromURL),
      });
      setFilters(removeUnusedFields(filterObjectFromURL));
      return;
    } else {
      setFilters({});
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("page")) {
      setPage(parseInt(params.get("page") as string));
      setOffSet((parseInt(params.get("page") as string) - 1) * limit);
    }
  }, []);

  return (
    <Flex
      vertical={true}
      justify="space-between"
      style={{
        height: "calc(100% - 8px)",
        paddingBottom: "8px",
        position: "relative",
      }}
    >
      <div>{children}</div>
      <div>
        <Pagination
          defaultCurrent={1}
          total={total}
          current={page}
          showSizeChanger
          onShowSizeChange={(_, size) => setLimit(size)}
          pageSize={limit}
          onChange={(page) => {
            setOffSet((page - 1) * limit);
            setPage(page);
            navigate({
              pathname: location.pathname,
              search: setPageParam(location.search, page),
            });
          }}
        />
      </div>
      {mode === "edit" && (
        <div
          style={{
            width: "100%",
            height: "100%",
            background: "#00000050",
            position: "absolute",
          }}
        />
      )}
    </Flex>
  );
};
