import { Form, Steps as AntSteps } from "antd";
import React from "react";

export const Steps: React.FC<{ control: any }> = ({ control }) => {
  return (
    <Form.Item
      label={control.field_name}
      //  tooltip={control.description}
    >
      <AntSteps size="small" current={control.value} items={control.options} />
    </Form.Item>
  );
};
