import { Flex, DetailsSection, DetailsForm } from "./TaskDetailsWraper.styled";
import React from "react";
import { FormProvider } from "react-hook-form";
import { TaskDetailsActions, TaskDetailsHeader } from "../../molecules";
import { useMode } from "../../organisms";
import { useTaskPageContetnSize } from "../../pages/task-page/useTaskPageContetnSize";

export const TaskDetailsWraper: React.FC<{
  children: any;
  methods: any;
  title: any;
  status: any;
  delete_permission: any;
}> = ({ children, methods, status, title, delete_permission }) => {
  const { mode } = useMode();
  const { detailsHeight } = useTaskPageContetnSize(mode);

  return (
    <FormProvider {...methods}>
      <Flex vertical={true} justify="space-between">
        <DetailsSection>
          <TaskDetailsHeader
            title={title}
            labels={[]}
            status={status}
            delete_permission={delete_permission}
          />
          <DetailsForm $detailsHeight={detailsHeight}>{children}</DetailsForm>
        </DetailsSection>
        <TaskDetailsActions />
      </Flex>
    </FormProvider>
  );
};
