const statuses: any = {
    NEW: {
        color: 'error',
        text: 'Utowrzono',
    },
    ASSIGNED: {
        color: 'warning',
        text: 'Przypisany',
    },
    IN_PROGRESS: {
        color: 'processing',
        text: 'W trakcie',
    },
    DONE: {
        color: 'success',
        text: 'Zakończony',
    }
}

const priority: any = {
    1: {
        color: '#87d068',
        text: 'Niski',
    },
    2: {
        color: '#2db7f5',
        text: 'Średni',
    },
    3: {
        color: '#ff0000',
        text: 'Wysoki',
    },
}

export const dataSourceConventer = (tasks: any) => {
    if (Array.isArray(tasks)) {
        return tasks.map((task) => {
            return {
                key: task.id,
                id: task.id,
                name: task.name,
                assigne: task.assigned,
                status: statuses[task.status],
                priority: priority[task.priority],
                created: task.created,
                unread_comments: task.unread_comments,
                state: task.state
            }
        })
    }
    return []
}

export const cratehardcodedControlsArray = (users: any) => {
    if (!users) return []
    return [
        {
            displaying_size: 13,
            uuid: "name",
            field_type: "input-text",
            value: null,
            field_name: "Nazwa",
            description: "",
            required: true,
            pre_name: "",
        },
        {
            displaying_size: 13,
            uuid: "assigned",
            field_type: "multi-select",
            value: null,
            options: users.map((user: any) => {
                return {
                    label: user.name,
                    value: user.user_id,
                };
            }),
            field_name: "Przypisane osoby",
            description: "",
            pre_name: "",
        },
        {
            displaying_size: 13,
            uuid: "priority",
            field_type: "select",
            value: null,
            options: [
                {
                    label: "Niski",
                    value: 1,
                },
                {
                    label: "Średni",
                    value: 2,
                },
                {
                    label: "Wysoki",
                    value: 3,
                },
            ],
            field_name: "Priorytet",
            description: "",
            pre_name: "",
        },
    ]

}