import React, { useMemo, useState } from "react";
import { Avatar, Flex, message, Tag, Tooltip } from "antd";
import { Table } from "./TaskList.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { createQuery, queryStringToObject } from "../filters/Filter.utils";

import { useTaskPageContetnSize } from "../../pages/task-page/useTaskPageContetnSize";
import { useQuery } from "react-query";
import { getTasks, getUsers } from "../../../service/client";
import {
  cratehardcodedControlsArray,
  dataSourceConventer,
} from "./TaskList.utils";
import { useFilters } from "../../templates/filter-context/useFilters";
import { TaskListHeader } from "../../molecules/";
import { TaskListWraper } from "../";
import { columns } from "./TaskList.consts";

export const TasksList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const paramas = queryStringToObject(location.search);

  const { tableHeight } = useTaskPageContetnSize();
  const { filters } = useFilters();
  const [limit, setLimit] = useState(20);
  const [offset, setOffSet] = useState(0);
  const [page, setPage] = useState(1);

  const query = createQuery({ ...filters, limit: limit, offset: offset });

  const { data: users } = useQuery<any, Error>(["/users"], () => getUsers());

  const hardcodedControls = cratehardcodedControlsArray(users);

  const [global_actions, setGlobal_actions] = useState();
  const [total, setTotal] = useState(null);
  const { data, isFetching } = useQuery<any, Error>(
    ["/tasks", query],
    () => getTasks(query),
    {
      onSuccess(data) {
        setTotal(data.count);
        Array.isArray(data.global_actions) &&
          setGlobal_actions(data.global_actions);
      },
    }
  );

  const tasks: any = useMemo(() => data && dataSourceConventer(data.results), [
    data,
  ]);

  const selected = (id: string) => {
    navigate({
      pathname: location.pathname,
      search: createQuery({ ...filters, id: id }),
    });
  };

  return (
    <TaskListWraper
      limit={limit}
      setLimit={setLimit}
      total={total}
      setOffSet={setOffSet}
      page={page}
      setPage={setPage}
    >
      <TaskListHeader
        setOffSet={setOffSet}
        setPage={setPage}
        hardcodedControls={hardcodedControls}
        total={total}
        global_actions={global_actions}
      />
      <Table
        columns={columns}
        loading={isFetching}
        dataSource={tasks}
        pagination={false}
        scroll={{ y: tableHeight }}
        size="small"
        rowClassName={(record: any) => {
          let calss = "";
          if (record.state.includes("AFTER_DEADLINE")) {
            calss = calss + "after-deadline ";
          }
          if (record?.key.toString() === paramas.id) {
            calss = calss + "selected-row ";
          }
          return calss;
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              selected(record.id);
            },
          };
        }}
      />
    </TaskListWraper>
  );
};
