import styled from "styled-components";
import { Flex as AntFlex } from "antd";
type StyledDivProps = {
    $detailsHeight: number;

};
export const Flex = styled(AntFlex)`
  height: calc(100% - 8px);
  padding-bottom: 8px;
`;

export const DetailsSection = styled.div`
  height: calc(100% - 8px);
`;

export const DetailsForm = styled.div<StyledDivProps>`
max-height:  ${props => `${props.$detailsHeight}px`};
height:  :  ${props => `${props.$detailsHeight}px`};
overflow-y: auto;
overflow-x: hidden;
`;