import { Form, Checkbox as AntCheckbox, Typography } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useMode } from "../../organisms";
import { FormIteam } from "../GlobalControl.styled";

export const Checkbox: React.FC<{ control: any }> = ({ control }) => {
  const pre_name =
    control.pre_name && control.pre_name !== "" ? `${control.pre_name}.` : "";
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: `${pre_name}${control.uuid}` });
  const { mode } = useMode();

  if (mode === "edit") {
    return (
      <Form.Item
        // label={control.field_name}
        tooltip={
          !control.writeable && mode === "edit"
            ? "Brak uprawnień do edycji"
            : control.description
        }
        required={control.required}
        validateStatus={invalid ? "error" : undefined}
        help={invalid ? error?.message : null}
      >
        <AntCheckbox
          {...field}
          checked={field.value}
          disabled={!control.writeable}
        >
          {control.field_name}
        </AntCheckbox>
      </Form.Item>
    );
  }

  return (
    <FormIteam
      label={control.field_name}
      // tooltip={control.description}
    >
      <Typography.Text strong>
        {control.value === true ? "Tak" : "Nie"}
      </Typography.Text>
    </FormIteam>
  );
};
