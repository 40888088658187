import { Col, Row, Skeleton } from "antd";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { queryStringToObject } from "../../organisms/filters/Filter.utils";
import { useForm } from "react-hook-form";
import { SupportedControls } from "../../molecules";
import {
  createFormInitialTaskValue,
  createShema,
  joiResolver,
} from "../single-order/SingleOrder.utils";
import { useQuery } from "react-query";
import { getGetTaskDetails } from "../../../service/client";
import { useBodyOverflow } from "./useBodyOverflow";
import { TaskDetailsWraper } from "../";

export const TaskDetails: React.FC = () => {
  useBodyOverflow();

  const location = useLocation();

  const paramas = queryStringToObject(location.search);

  const { data, isFetching } = useQuery<any, Error>(
    ["/task-details", paramas?.id],
    () => getGetTaskDetails(paramas?.id),
    {
      enabled: !!paramas?.id,
    }
  );
  const status = data && data.status;
  const title = data && data.name;
  const delete_permission = data && data.delete_permission;
  const schema = data && createShema(data.content);

  const methods = useForm({
    resolver: schema && joiResolver(schema),
  });

  useEffect(() => {
    if (data) {
      const contetntControls = createFormInitialTaskValue("", data.content);
      methods.reset({
        ...contetntControls,
      });
      return;
    }
  }, [data]);

  if (isFetching) {
    return (
      <>
        <Skeleton.Input active size={"large"} />
        <Skeleton active paragraph={{ rows: 10 }} />
      </>
    );
  }

  if (data) {
    return (
      <TaskDetailsWraper
        methods={methods}
        title={title}
        status={status}
        delete_permission={delete_permission}
      >
        {data?.content &&
          data.content.length > 0 &&
          data.content
            .sort((a: any, b: any) => {
              return a.position - b.position;
            })
            .map((control: any) => {
              return (
                <Row gutter={16} key={control.uuid}>
                  <Col span={control.displaying_size}>
                    <SupportedControls control={control} />
                  </Col>
                </Row>
              );
            })}
      </TaskDetailsWraper>
    );
  }

  return null;
};
