import { Flex, Layout, Menu, Space } from "antd";
import { useMemo, useState } from "react";

import React from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { MenuProps } from "antd";
import { useQueryClient } from "react-query";
import { findModel } from "../../components/templates/main-template/MainTamplate.utils";
import { replacePolishChars } from "./SlideMenu.utils";

import { UserInfo } from "../../components/molecules";
export const SlideMenu: React.FC<{
  refetch: any;
  collapsed: any;
  setCollapsed: any;
}> = ({ refetch, collapsed, setCollapsed }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const data: any = queryClient.getQueryData(["/models"]);
  const user: any = queryClient.getQueryData(["/current-user"]);

  const location = useLocation();

  type MenuItem = Required<MenuProps>["items"][number];
  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    path?: string,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
      onClick: () => {
        path && navigate(path);
      },
    } as MenuItem;
  };

  const items: MenuItem[] = [];
  data &&
    data
      .sort((a: any, b: any) => {
        return a.position - b.position;
      })
      .forEach((element: any) => {
        items.push(
          getItem(
            element.name,
            element.id,
            replacePolishChars(element.name.toLowerCase().replace(/ /g, "-"))
          )
        );
      });

  const { model } = useMemo(() => findModel(data, location.pathname.slice(1)), [
    data,
    location,
  ]);
  const selectedKeys: any = model ? model.id.toString() : "";

  if (data) {
    return (
      <>
        <Layout.Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            zIndex: 1000,
          }}
        >
          <Flex
            justify="space-between"
            vertical
            style={{
              height: "100%",
            }}
          >
            <Space direction="vertical">
              <img
                src={user?.company_logo}
                alt=""
                style={{
                  width: "80%",
                  marginLeft: "10%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              />
              <Menu
                theme="dark"
                selectedKeys={selectedKeys}
                defaultSelectedKeys={selectedKeys}
                mode="inline"
                items={items}
              />
            </Space>
            <UserInfo refetch={refetch} collapsed={collapsed} />
          </Flex>
        </Layout.Sider>
      </>
    );
  }
  return null;
};
