import { Layout } from "antd";
import { MainTemplate } from "../../components/templates";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { FilterContextProvider } from "../../components/templates/filter-context/FilterContextProvider";

export const MainContent: React.FC = () => {
  return (
    <Layout.Content style={{ margin: "0 16px" }}>
      <Routes>
        <Route
          path="/:name?/:id?"
          element={
            <FilterContextProvider>
              <MainTemplate />
            </FilterContextProvider>
          }
        />
      </Routes>
    </Layout.Content>
  );
};
