import { Button, Form, notification, Modal, Result, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addFormModal, getFormModalControls } from "../../../service";
import { ModalForm } from "../../molecules";

import { SingleOrderContextProvider, useMode } from "../../organisms";
import {
  createFormInitialValue,
  createShema,
  joiResolver,
} from "../../organisms/single-order/SingleOrder.utils";
import { MainTemplateLoading } from "../../templates/main-template/MainTemplate.styled";

export const FormModal: React.FC<{ control: any }> = ({ control }) => {
  const { mode, global_actions } = useMode();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const { data, error, isError, isFetching } = useQuery<any, Error>(
    [`/form-modal-${control.uuid}`, control.uuid],
    () => getFormModalControls(control.value.fetch_url),
    {
      enabled: open,
    }
  );
  const schema = data && createShema(data.model);
  const methods = useForm({
    resolver: schema && joiResolver(schema),
  });

  const values = methods.watch();

  const { mutateAsync, isLoading } = useMutation(addFormModal, {
    onSuccess: async () => {
      try {
        await queryClient.refetchQueries("/orders");
        await queryClient.refetchQueries("/tasks");
        notification.success({
          message: "Zapisano zmiany",
          style: {
            background: "#52c41a30",
          },
        });
        methods.reset();
        setOpen(false);
      } catch (error) {
        notification.error({
          message: "Pobierania nowych danych",
          style: {
            background: "#ff4d4f30",
          },
        });
        console.error("Wystąpił błąd podczas odświeżania danych:", error);
        setOpen(false);
      }
    },

    onError: () => {
      notification.error({
        message: "Błąd zapisu danych",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const submit = () => {
    methods.handleSubmit(async (data) => {
      await mutateAsync({ url: control.value.save_url, body: data });
    }, console.error)();
  };

  const canel = () => {
    methods.reset();
    setOpen(false);
  };

  useEffect(() => {
    if (open && data) {
      methods.reset(createFormInitialValue(data.model));
      return;
    }
  }, [open, data]);

  return (
    <>
      <Form.Item
        // tooltip={
        //   !control.writeable && mode === "edit"
        //     ? "Brak uprawnień do edycji"
        //     : control.description
        // }
        style={{ margin: 0, marginTop: global_actions ? "" : "30px" }}
      >
        <Button
          type="primary"
          disabled={mode === "edit" || !control.writeable}
          onClick={() => setOpen(true)}
        >
          {control.field_name}
        </Button>
      </Form.Item>
      <Modal
        maskClosable={false}
        confirmLoading={isLoading}
        title="Dodaj nowy rekord"
        centered
        open={open}
        okButtonProps={{
          disabled: isError || isFetching || isLoading,
          loading: isFetching || isLoading,
        }}
        onOk={() => {
          submit();
        }}
        onCancel={canel}
        width={1000}
        destroyOnClose
      >
        {isFetching && (
          <MainTemplateLoading>
            <Spin size="large" />
          </MainTemplateLoading>
        )}

        {data && (
          <SingleOrderContextProvider disableReadMode={true}>
            <FormProvider {...methods}>
              <ModalForm controls={data.model} hardcodedControls={[]} />
            </FormProvider>
          </SingleOrderContextProvider>
        )}

        {isError && (
          <Result
            status="403"
            title="BŁĄD"
            subTitle="Przepraszamy, wystąpił błąd. Skontaktuj się z administratorem"
          />
        )}
      </Modal>
    </>
  );
};
