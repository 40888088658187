import { UploadOutlined } from "@ant-design/icons";
import { Form, Upload, Button as AntButton } from "antd";
import React from "react";
import { useMode } from "../../organisms";
import { deleteAttach } from "../../../service/client";
import Cookies from "js-cookie";

export const Attachments: React.FC<{ control: any }> = ({ control }) => {
  const { mode } = useMode();

  const props = {
    onRemove: (e: any) => e.id && deleteAttach(e.id),
    defaultFileList: control.value,
  };

  const customRequest = (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;

    const formData = new FormData();
    formData.append("file", file);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", control.url, true);
    xhr.withCredentials = true;
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${Cookies.get("accessToken")}`
    );

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        onSuccess("Upload successful", file);
      } else {
        onError("Upload failed");
      }
    };

    xhr.onerror = () => {
      onError("Upload failed");
    };

    xhr.send(formData);
  };

  return (
    <Form.Item
      label={control.field_name}
      // tooltip={
      //   mode === "edit" && !control.writeable
      //     ? "Brak uprawnień do edycji"
      //     : control.description
      // }
    >
      <Upload {...props} multiple customRequest={customRequest}>
        <AntButton
          disabled={mode === "edit" || !control.writeable}
          icon={<UploadOutlined />}
        >
          Dodaj plik
        </AntButton>
      </Upload>
    </Form.Item>
  );
};
