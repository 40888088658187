import React from "react";
import { Button, notification, Popconfirm, Row, Tag } from "antd";
import { Space, Title } from "./TaskDetailsHeader.styled";
import { useMode } from "../../organisms";
import { useMutation, useQueryClient } from "react-query";
import { deleteTask, updateTask } from "../../../service/client";
import { queryStringToObject } from "../../organisms/filters/Filter.utils";
import { useNavigate } from "react-router-dom";

export const TaskDetailsHeader: React.FC<{
  title: string;
  labels: any;
  status: any;
  delete_permission: any;
}> = ({ title, labels, status, delete_permission }) => {
  const queryClient = useQueryClient();
  const paramas = queryStringToObject(location.search);
  const navigate = useNavigate();

  const { mode, setMode } = useMode();
  const updateTaskMutation = useMutation(updateTask, {
    onSuccess: async () => {
      try {
        await queryClient.refetchQueries("/tasks");
        await queryClient.refetchQueries("/task-details", paramas?.id);
        notification.success({
          message: "Zapisano zmiany",
          style: {
            background: "#52c41a30",
          },
        });
        setMode("read");
      } catch (error) {
        notification.error({
          message: "Błąd Pobierania nowych danych",
          style: {
            background: "#ff4d4f30",
          },
        });
        console.error("Wystąpił błąd podczas odświeżania danych:", error);
      }
    },
    onError: () => {
      notification.error({
        message: "Błąd zapisu danych",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const { mutateAsync, isLoading } = updateTaskMutation;

  const deletaTaskMutation = useMutation(deleteTask, {
    onSuccess: async () => {
      try {
        await queryClient.refetchQueries("/tasks");
        notification.success({
          message: "Usunięto pomyślnie",
          style: {
            background: "#52c41a30",
          },
        });
        navigate({
          pathname: location.pathname,
          search: "",
        });
        setMode("read");
      } catch (error) {
        notification.error({
          message: "Błąd Pobierania nowych danych",
          style: {
            background: "#ff4d4f30",
          },
        });
        console.error("Wystąpił błąd podczas odświeżania danych:", error);
      }
    },
    onError: () => {
      notification.error({
        message: "Błąd uswania",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const {
    mutateAsync: deletaTaskMutateAsync,
    isLoading: deletaTaskIsLoading,
  } = deletaTaskMutation;

  return (
    <Row justify="space-between" id="task-details-header">
      <Space size={"small"} direction="vertical">
        <Title level={4} copyable>
          {title}
        </Title>
        <Space size={"small"} direction="horizontal">
          {labels?.map((e: any) => {
            return (
              <Tag color={e?.color} key={e?.text}>
                {e?.text}
              </Tag>
            );
          })}
        </Space>
      </Space>
      <Space size={"small"} direction="horizontal">
        {(status === "NEW" || status === "ASSIGNED") && (
          <Button
            onClick={() =>
              mutateAsync({
                id: paramas?.id,
                body: {
                  status: "IN_PROGRESS",
                },
              })
            }
            type="primary"
            loading={isLoading}
          >
            Rozpocznij
          </Button>
        )}

        {status === "IN_PROGRESS" && (
          <Button
            onClick={() =>
              mutateAsync({
                id: paramas?.id,
                body: {
                  status: "DONE",
                },
              })
            }
            type="primary"
            loading={isLoading}
          >
            Zakończ
          </Button>
        )}

        {delete_permission && (
          <Popconfirm
            title="Usuń pozycje"
            description="Czy na pewno chcesz usunąć wybraną pozycję?"
            onConfirm={() => deletaTaskMutateAsync(paramas?.id)}
            okText="Tak"
            cancelText="Nie"
          >
            <Button danger disabled={deletaTaskIsLoading}>
              Usuń
            </Button>
          </Popconfirm>
        )}

        <Button
          disabled={isLoading}
          onClick={() => setMode(mode === "read" ? "edit" : "read")}
        >
          Edytuj
        </Button>
      </Space>
    </Row>
  );
};
