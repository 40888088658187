import { replacePolishChars } from "../../../view/slide-menu/SlideMenu.utils";

export const findModel = (models: any, name: any) => {
    let error = false;
    let model = undefined;
    if (name) {
        model = models?.find(
            (element: any) => {
                return replacePolishChars(element.name.toLowerCase().replace(/ /g, "-")) === replacePolishChars(name)
            }
        );
        if (model) {
            return { model, error };
        } else {
            let error = true;
            return { model, error };
        }
    }
    return { model, error };
}