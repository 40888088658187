import React, { useState } from "react";
import { Layout, Spin } from "antd";
import { SlideMenu, MainContent } from "./view";
import { useQueries } from "react-query";
import { getModel } from "./service";
import { MainTemplateLoading } from "./components/templates/main-template/MainTemplate.styled";
import { getCurrentUser } from "./service/client";
import { Footer, Header } from "./components/organisms/";
import { LoginForm } from "./components/organisms/login-form/LoginForm";

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const results = useQueries([
    {
      queryKey: ["/current-user"],
      queryFn: () => getCurrentUser(),
      refetchInterval: 60000 * 10,
      retry: false,
      onError() {
        document.title = "Manager";
      },
      onSuccess(data: any) {
        if (data?.company_name)
          document.title = data.company_name + " - Manager";
      },
    },
    { queryKey: ["/models"], queryFn: () => getModel(), retry: false },
  ]);

  const isLoggedIn =
    results[0].status === "success" &&
    results[0].data &&
    Object.keys(results[0].data).length > 0 &&
    results[0].data?.code !== "token_not_valid";

  const refetch = () => {
    results[0].refetch();
    results[1].refetch();
  };

  if (results[0].isLoading || (!isLoggedIn && results[1].isLoading)) {
    return (
      <MainTemplateLoading>
        <Spin size="large" />
      </MainTemplateLoading>
    );
  }

  if (isLoggedIn && Array.isArray(results[1].data)) {
    return (
      <Layout hasSider>
        <SlideMenu
          refetch={refetch}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        <Layout
          style={{
            minHeight: "100vh",
            marginLeft: collapsed ? 80 : 200,
            minWidth: "1000px",
          }}
        >
          <Header />
          <MainContent />
          <Footer />
        </Layout>
      </Layout>
    );
  }

  if (!isLoggedIn) {
    return <LoginForm refetch={refetch} />;
  }

  return null;
};

export default App;
