import { useEffect } from "react";

export const useBodyOverflow = () => {
    useEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).cssText;
        document.body.style.cssText += ";overflow: hidden;";
        return () => {
            document.body.style.cssText = originalStyle;
        };
    }, []);
}

